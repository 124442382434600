import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                “A MUST visit!!! This gym is definitely not like the other are indoor play gyms. It offers inclusive activities for all ages and really live up to their motto of being for all kids. They have a separate play area for the infants and younger toddlers, a teen room, and a quiet room to help if a kiddo is overstimulated. The bathrooms also include changing tables that are specially designed for kiddos of all needs. The thing that really sets them apart is they have staff on the floor at the big stations ready and willing to help the kiddos. All of them were so kind and happy to be to there. It wasn’t just a job but a passion. You definitely have to check this gym out!”
</p>
                            <p className="overview">Monica C</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Fantastic Local business that provides a safe place for children to play. My toddler’s love to spend hours upon hours here. They also have themed nights and parents night out they host. Each visit I’ve seen the staff go above and beyond in all aspects even playing imaginary games with the kids. Amazing staff! Amazing place to visit! 5 Stars”</p>
                            <p className="overview">Farhan M</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “We had the best party experience! We had our son’s birthday party here and couldn’t have asked for a better host. We had SO MUCH help setting up, decorating, serving food, etc. plus they were so wonderful playing with the kiddos, keeping tabs on the party room, even helping us pack everything up afterwards. I truly can’t say enough about how wonderful WRTS was to us, making the day easy and allowing us to enjoy the party. Huge thank you!!”</p>
                            <p className="overview">
                                Casey G</p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                                “We LOVE We Rock the Spectrum - Cary! My four & six year old sons did their day camp for the last two weeks and have also been going to their weekend parties. They really liked it! This place has been truly a breath of fresh air - a place that is designed to accommodate my kids, releasing me from the usual worries that go with private drop-off programs like whether they will be patient and flexible with my kids and whether I'll get a call asking me to pick them up early because of "bad behavior" (which has usually been code for the camp having failed to provide adequate support). The place has amazing gym equipment to give my kids the proprioceptive and vestibular input (aka joy!!) they need. There's also an arts/crafts activity as part of each camp day. Critically, while the kids are given a schedule of activities (gym time, arts/crafts, lunch) and encouraged to follow it, there is plenty of flexibility if, for example, my kid really wants to just do gym equipment for the day. They can allow this because of the extremely high counselor-to-camper ratio.”</p>
                            <p className="overview">
                                David C</p>
                        </div> 

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “A fun and safe environment for the kids, with brand new equipment you won’t find at any other play cafe or indoor playground. Trampoline and zip-line were big hits during our visit. Owners are super friendly, and really care about the kids having some good safe fun while also burning off some energy.”</p>
                            <p className="overview">
                                Melanie D</p>
                        </div> 
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
